import "@styles/globals.scss";
import config from "@utils/config";
import type { AppProps } from "next/app";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import routeStore from "@utils/routeStore";
import _ from "lodash";

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    _.debounce(() => {
      TagManager.initialize({ gtmId: config.GTM_ID });
    }, 3000)();
  }, []);
  routeStore.hotel = _.get(pageProps, "hotel", "");
  return <Component {...pageProps} />;
}

export default MyApp;
